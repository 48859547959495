import { Component, Inject, OnInit, inject } from "@angular/core";
import { ModalComponent } from "../../../../components/modal/modal.component";
import { Option } from "src/app/models/option";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Subscription, firstValueFrom } from 'rxjs';
import { ModalSucess } from '../../../../components/modal/modal-success/modal-success.component';
import { CustomValidators } from 'src/app/form/custom-validators';
import { BankService } from 'src/app/services/bank.service';
import { Contract } from 'src/app/models/bank/contract';
import { Statement } from 'src/app/models/bank/statement';
import { IncidentRequest, IncidentResponse } from 'src/app/models/incident/incident';
import { IncidentService } from 'src/app/services/incident.service';
import { ModalConfirmComponent } from 'src/app/components/modal/modal-confirm/modal-confirm.component';
import { LogService } from "src/app/services/log.service";
import { EnumProduct } from "src/app/enum/products";
import { EnumReportIncident } from "src/app/enum/report-incident";
import { DOCUMENT } from "@angular/common";


@Component({
  selector: "app-modal-bank-payment-reversal",
  templateUrl: "./modal-bank-payment-reversal.component.html",
  styleUrls: ["./modal-bank-payment-reversal.component.scss"],
})
export class ModalBankPaymentReversalComponent extends ModalComponent<ModalBankPaymentReversalComponent> implements OnInit
{
  public isBtnEnviarLoading: boolean = false;
  public isLoadingData: boolean = false;

  public payments: Statement[];
  public advances: Statement[];
  public debts: Statement[];

  public subject: Option;
  public documentNumber: string;
  public form: FormGroup;

  public contractSelected: Contract;
  public $contractSubscriber: Subscription;

  get equalValuesError() {
    return (
      this.form.hasError("notMismatch") &&
      this.form.get("replaceInstallment").value > 0 &&
      this.form.get("wrongPayedInstallment").value > 0
    );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {subject: Option, documentNumber: string},
    private formBuilder: FormBuilder,
    private bankService: BankService,
    private incidentService: IncidentService,
    private dialog: MatDialog
  ) {
    super();

    this.subject = data.subject;
    this.documentNumber = data.documentNumber;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      wrongPayedInstallment: [null, [Validators.required]],
      replaceInstallment: [null, [Validators.required]],
    });

    this.form.addValidators(
      CustomValidators.notMatchValidator(
        "wrongPayedInstallment",
        "replaceInstallment"
      )
    );

    this.$contractSubscriber = this.bankService.emitContractSelected.subscribe(
      (x: Contract) => {
        this.contractSelected = x;
      }
    );

    this.getPayments();
  }

  private async getPayments(): Promise<void>{
    this.isLoadingData = true;
    let today = new Date();
    firstValueFrom(this.bankService.statement(this.contractSelected.contractNumber, false))
      .then((resp: Statement[]) => {
        this.advances = new Array<Statement>();
        this.debts = new Array<Statement>();
        resp.forEach(item => {
          let dueDate = new Date(item['dueDate'])
          if(item['status'] == 'A')
            this.debts.push(item);
          if(item['status'] == 'L'){
            if(this.checkAdvancement(item))
              if(dueDate > today){
                this.advances.push(item);
              }
          }
        });
      })
      .finally(() => this.isLoadingData = false);
  }

  private checkAdvancement(item: Statement): boolean {
    var dueMonth = parseInt(item["dueDate"].slice(5, 7));
    var dueYear = parseInt(item["dueDate"].slice(0, 4));
    var paymentMonth = parseInt(item["paymentDate"].slice(5, 7));
    var paymentYear = parseInt(item["paymentDate"].slice(0, 4));
    if(paymentYear < dueYear || paymentMonth < dueMonth)
      return true;
    return false;
  }

  private createIncidentObject(): IncidentRequest {
    return {
      subject: 11,
      description:
        "SOLICITACAO REALIZADA VIA SITE TRANSACIONAL - Inversão de Pagamento solicitada para as alterações de:\n" +
        `CPF/CNPJ: ${this.documentNumber}\n`+
        `Contrato: ${this.contractSelected.contractNumber}\n`+
        "Parcela paga por engano: "+ this.form.get("wrongPayedInstallment").value + ".\nParcela correta: " + this.form.get("replaceInstallment").value + ".",
      product: 1,
    };
  }


  public async onClickBtnSubmit(event?: any) {
    this.isBtnEnviarLoading = true;

    if (!this.form.valid) {
      return;
    }

    let messageModalSuccess: ModalSucess = {
      title: "Atenção",
      message: "Prezado cliente, iremos avaliar a solicitação. Pedimos a gentileza que aguarde o contato da nossa Central de Relacionamento.",
      style: {width: '900px'}
    }

    await firstValueFrom(this.incidentService.create(this.createIncidentObject()))
      .then(() => {
        this.onSubmit(messageModalSuccess);
        this.logFeature(EnumReportIncident.BANK_PAYMENT_REVERSAL, EnumProduct.BANK);
      })
      .finally(() => {
        this.isBtnEnviarLoading = false;
      })
  }

  public async onClickBtnClose(): Promise<void>{

    if(this.form.dirty){
      firstValueFrom(this.dialog.open(ModalConfirmComponent, {
        maxWidth: "900px",
        data: "Tem certeza que deseja cancelar?"
      })
      .afterClosed())
      .then((resp: boolean) => resp ? this.onClose(false) : null)
    } else {
      this.onClose(false)
    }

  }

}
