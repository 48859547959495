<app-modal class="content density-lower backdrop-blur" title="Confirmação E-mail" data-cy="modal-login-confirm-email-data"
  [disableButtonClose]="isBtnEnviarLoading">
  <div class="modal-content density-mat-form-3">

    <section class="text space-between-top-bottom">
      Para a sua segurança, por favor, selecione um dos e-mails abaixo para uma verificação de autenticidade.
    </section>

    <form (keydown.enter)="$event.preventDefault(); onClickBtnSubmit()" [formGroup]="form" >
      <div class="form-field">
        <label> <span class="red">*</span> Selecione o e-mail</label>
        <mat-form-field appearance="outline" class="background-white" subscriptSizing="dynamic">
          <mat-select placeholder="Selecione o e-mail" [(value)]="selectedEmail" (selectionChange)="onEmailSelect($event)">
            <mat-option *ngFor="let hiddenEmail of hiddenEmails" [value]="hiddenEmail">{{ hiddenEmail }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="content-header-subtitle-recognize">
          <span class="dark-bolder cursor-pointer" (click)="notRecognizeEmail()" data-cy="new-account"> Não reconhece nenhum e-mail?</span>
        </div>
      </div>

      <div class="form-field">
        <label><span class="red">*</span> Insira o e-mail selecionado: </label>
        <mat-form-field appearance="outline" class="background-white">
          <input matInput id="email-input" type="text" [(ngModel)]="email"
            formControlName="email" (input)="onInputChange()" />
          <mat-error *ngIf="form.get('email').hasError('required')" data-cy="error-email-required">
            <strong> O e-mail é obrigatório </strong>
          </mat-error>
          <mat-error *ngIf="form.get('email').hasError('email')">
            <strong>O formato do e-mail é inválido.</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div class="modal-bottom">
    <app-button width="100px" (onClick)="onClickBtnClose()" [disabled]="isBtnEnviarLoading" datacy="Cancelar">
      <span class="button-label"> Cancelar </span>
    </app-button>

    <app-button type="submit" color="primary" [loading]="isBtnEnviarLoading" [disabled]="!form.valid || !emailValidado" datacy="update-button" (onClick)="onClickBtnSubmit()"
      datacy="Enviar">
      <span class="button-label"> Enviar</span>
    </app-button>

  </div>
</app-modal>
