import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if(environment.enableAppDynamics){
  (window as any).env = environment;
  const scriptAppDynamics = document.createElement('script');
  scriptAppDynamics.src = '/assets/AppDConfig.js';
  scriptAppDynamics.async = true;
  document.head.append(scriptAppDynamics);

  const scriptAppDynamicsCDN = document.createElement('script');
  scriptAppDynamicsCDN.src = '//cdn.appdynamics.com/adrum/adrum-24.2.0.4431.js';
  scriptAppDynamicsCDN.async = true;
  document.head.append(scriptAppDynamicsCDN);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
